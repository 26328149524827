$primary: #3a8f78;
$primary-dark: #0c3a2d;
$secondary: #d76238;

@mixin phone-small {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin tablet-large {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin pc-small {
  @media (max-width: 1600px) {
    @content;
  }
}
