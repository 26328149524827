@import "../globals.scss";

.container {
  background-color: $primary;
  //   height: calc(100vh - 82px);
  height: 700px;
  //   height: calc(80vh - 64px);
  display: flex;
  align-items: center;
  max-height: calc(100vh - 70px);
  @include tablet {
    height: unset;
    min-height: calc(100vh - 70px);
    max-height: unset;
    padding: 50px 0;
  }
}

.left,
.right {
  flex: 1;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @include pc-small {
    * {
      text-align: center;
    }
  }
  h1 {
    font-weight: bold;
    font-size: 52px;
    line-height: 62px;
    color: #ffffff;
    @include tablet {
      font-size: 40px;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    @include tablet {
      font-size: 20px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
  input {
    background: #ffffff;
    border: 1px solid rgba(38, 50, 56, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 12px;
    // font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #263238;
    max-width: 377px;
    width: 100%;
  }
  button {
    max-width: 352px;
    width: 100%;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 246px;
  @include pc-small {
    padding-right: 0;
    justify-content: center;
  }
  .phone {
    img {
      height: 589px;
      max-height: 80vh;
    }
    &:nth-child(2) {
      margin-top: 350px;
    }
  }
  @include tablet-large {
    display: none;
  }
}
