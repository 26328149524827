@import "../globals.scss";

.container {
  margin-top: 50px;
  background-color: $primary-dark;
  padding: 50px 100px;
  @include pc-small {
    padding: 40px;
  }
  @include tablet {
    padding: 20px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 100px;
  @include tablet-large {
    flex-direction: column;
    gap: 50px;
    align-items: center;
    padding-top: 20px;
  }
}

.left,
.right {
  display: flex;
  // gap: 100px;
  // flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
  @include tablet {
    flex-direction: column;
    gap: 10px;
  }
}

.left {
  flex: 2;
  justify-content: center;
}

.right {
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  @include pc-small {
    justify-content: center;
  }
}

.logoSec {
  padding-bottom: 20px;
  align-items: center;
}

.logo {
  width: 288px;
  @include tablet {
    // width: 150px;
  }
}

.slogan {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 1.5px;
  color: #ffffff;
  @include tablet {
    font-size: 15px;
    text-align: center;
  }
}

.bullets {
  .bullet {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      font-family: "Open Sans";
      font-size: 18px;
      line-height: 58px;
      color: #ffffff;
      width: 250px;
      @include tablet {
        font-size: 15px;
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.scan {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.download {
  align-self: center;
  @include pc-small {
    flex-direction: row;
    // flex-wrap: wrap;
  }
  @include phone {
    svg {
      width: 150px;
    }
  }
}

.copyright {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  p {
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    @include tablet {
      font-size: 18px;
    }
  }
}

.QR {
  align-items: center;
}

.bulletSec {
  display: flex;
  flex-direction: row;
  gap: 30px;
  @include phone {
    flex-direction: column;
    gap: 20px;
  }
}
