@import "../globals.scss";

.container {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.primary.filled {
  background-color: $primary;
  color: white;
}

.secondary.filled {
  background-color: $secondary;
  color: white;
}

.outlined {
  background-color: transparent;
  color: white;
  border: 1px solid #f4f7fd;
}
