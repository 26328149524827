@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding: 20px 50px;
  @include tablet {
    gap: 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 42px;
    display: flex;
    align-items: center;
    color: #0c3a2d;
    text-align: center;
    @include tablet {
      font-size: 30px;
      margin-top: 20px;
    }
  }
  button {
    max-width: 352px;
    width: 100%;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 855px;
  width: 100%;
}

.instruction {
  h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    @include tablet {
      font-size: 20px;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    @include tablet {
      font-size: 15px;
    }
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
