@import "../globals.scss";

.container {
  display: flex;
  justify-content: space-between;
  padding: 150px 0 80px 0;
  position: relative;
  @include tablet {
    padding: 50px 0;
    justify-content: center;
  }
}

.invisible {
  opacity: 0;
  transform: translateY(10px);
}

.features {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 100px;
  @include tablet {
    padding: 0 20px;
    align-self: center;
  }
}

.trigger {
  height: 20px;
  width: 20px;
  pointer-events: none;
  position: absolute;
}

.image {
  flex: 1;
  max-width: 1100px;
  img {
    width: 100%;
  }
  @include tablet {
    display: none;
  }
}
