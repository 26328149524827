@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;900");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900");
@import url("https://fonts.googleapis.com/css2?family=Avenir+Next:wght@100;200;300;400;500;600;700;900");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;900");
@import "./globals.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato";
}
