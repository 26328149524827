.container {
  display: flex;
  gap: 22px;
  svg {
    height: 50px;
    width: 50px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #272727;
  }
  p {
    font-size: 13px;
    line-height: 16px;
    color: #272727;
    max-width: 480px;
  }
}
