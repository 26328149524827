@import "../globals.scss";

.container {
  background-color: $primary;
  padding: 18px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 5;
  @include tablet {
    padding: 18px 20px;
  }
  &.scrolled {
    box-shadow: 0 2px 9px 0 #00000038;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  @include phone-small {
    button:first-of-type {
      display: none;
    }
  }
}

.logo {
  width: 142px;
}
